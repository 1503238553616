.notification-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px;
  font-size: 14px;
}
.noti-component {
  background-color: lightgray;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 5px;
  border-radius: 5px;
  padding: 5px 5px 0px 5px;
  font-size: 14px;
}
.noti-component.read {
  background-color: rgb(240, 239, 239);
}

/*avatar*/
.noti-avt {
  width: 70px;
  height: 70px;
  background-color: white;
  margin-right: 5px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  overflow: hidden;
}
.noti-avt img {
  width: 70px;
  height: 70px;
}

/*value*/
.noti-value {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.noti-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.noti-main {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.noti-tick {
  margin-left: 5px;
  margin-right: 5px;
  color: lightgray;
}
.noti-tick.blue {
  margin-left: 5px;
  margin-right: 5px;
  color: #0090ed;
}

.noti-tick-all-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.noti-tick-all {
  width: fit-content;
  color: #0090ed;
  margin-right: 5px;
}

/*btn*/
.noti-btn-con {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.noti-mobile-con {
  height: calc(100vh - 120px);
  overflow: auto;
  display: flex;
  flex-direction: column;
}
