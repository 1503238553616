.ordered-input.blue input {
  color: blue;
}
.ordered-input.red input {
  color: red;
}
.ordered-input.lightgray input {
  color: lightgray;
}
.ordered-input.yellow input {
  color: yellow;
}
