.guide-page-main {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}
.guide-page-cpm {
  background-color: rgb(231, 229, 229);
  width: calc(100vw - 10px);
  max-width: 800px;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}
.guide-page-avt {
  width: 120px;
  height: 90px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 5px;
}
.guide-page-avt img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media all and (min-width: 992px) {
  .guide-page-avt {
    width: 200px;
    height: 150px;
  }
}
.guide-page-no-avt {
  width: 100%;
  height: 100%;
  background-color: white;
  color: transparent;
}
.guide-page-text {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.guide-page-title {
  font-weight: 500;
}
