/*google login*/
.login-google {
  margin-top: 15px;
  text-align: center;
  width: calc(100vw - 20px);
  max-width: 326px;
}

/*login container*/
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background-color: #fbfbfb;
  min-height: 100vh;
}

/*logo*/
.logo-container {
  width: 100vw;
  height: max(80px, min(calc((100vw - 991px) * 9999), 120px));
  padding: 15px;
  background-color: white;
}
.login-logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/*login main*/
.login-main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 10px;
  padding-top: max(20px, min(calc((100vw - 991px) * 9999), 40px));
}
@media all and (max-width: 991px) {
  .login-image {
    display: none;
  }
}
.login-image {
  margin-right: 60px;
}
.login-title {
  color: #42494d;
  font-size: max(20px, min(calc((100vw - 991px) * 9999), 30px));
  font-weight: 700;
}

/*login form*/
.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*login input*/
.login-input {
  position: relative;
  margin-top: 25px;
  width: calc(100vw - 20px);
  max-width: 326px;
  border: 2px solid lightgray;
  border-radius: 8px 8px 8px 0px;
}
.login-input > input {
  width: 100%;
  height: max(40px, min(calc((100vw - 991px) * 9999), 40px));
  padding-left: 10px;
  opacity: 0;
  border: none;
  font-weight: 500;
  border-radius: 8px 8px 8px 0px;
}
.login-input.focus-input {
  border-color: #009250;
}
.login-input-title {
  position: absolute;
  top: 20%;
  left: 20px;
  background-color: #fbfbfb;
  translate: 1s;
  color: #42494d;
  font-size: 18px;
  font-weight: 500;
  color: lightgray;
  transition: 0.5s;
}
.login-input.focus-input .login-input-title {
  top: -35%;
  color: #42494d;
}
.login-input.focus-input > input {
  opacity: 1;
}
.login-input.validate-input > input {
  opacity: 1;
}
.login-input.validate-input .login-input-title {
  top: -35%;
  color: #42494d;
}

/*login button*/
.login-btn {
  cursor: pointer;
  background-color: #ff000a;
  color: white;
  font-weight: 600;
  font-size: 15px;
  border-radius: 10px;
  box-shadow: 4px 4px 5px gray;
  width: calc(100vw - 20px);
  max-width: 326px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px 8px 8px 0px;
  position: relative;
  overflow: hidden;
}
.login-btn::before {
  content: '';
  background-color: #d4000d;
  border-radius: 8px 8px 8px 0px;
  top: 0px;
  left: -100%;
  position: absolute;
  width: 100%;
  height: 100%;
  transition: 0.5s;
}
.login-btn:hover::before {
  top: 0px;
  left: 0;
}
.login-btn::after {
  content: 'ĐĂNG NHẬP';
  border-radius: 8px 8px 8px 0px;
  top: 0px;
  left: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

/*text btn login*/
.text-btn-login-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: calc(100vw - 20px);
  max-width: 326px;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.text-btn-login-container > a {
  text-decoration: none;
  color: #ff000a;
}
.text-btn-login-container > a:hover {
  color: #d4000d;
}
/*confirm*/
.login-confirm-text-container {
  width: calc(100vw - 20px);
  max-width: 326px;
  margin-top: 15px;
  font-size: 15px;
  font-weight: 500;
}
