.active-item-status {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.active-sim-keyword {
  margin: 2px 10px;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  max-width: 300px;
}
.active-sim-keyword input {
  width: 100%;
  height: 25px;
}
.act-icon {
  color: #ed4d2d;
  cursor: pointer;
  margin-left: 10px;
}
.act-icon.error {
  color: lightgray;
}
.act-icon:active {
  color: blue;
}
.act-icon.error:active {
  color: lightgray;
}

/*popup*/
.act-sim-popup {
  display: none;
}
.act-sim-popup.show {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  background-color: white;
  width: max(300px, min(calc((100vw - 991px) * 9999), 700px));
  max-height: 80vh;
  padding: max(5px, min(calc((100vw - 991px) * 9999), 10px));
  border-radius: 5px;
  box-shadow: 5px 5px 5px lightgray, 5px -5px 5px lightgray, -5px -5px 5px lightgray,
    -5px 5px 5px lightgray;
  overflow-y: scroll;
  z-index: 100;
  top: 40vh;
}
@media all and (max-width: 991px) {
  .act-sim-popup.show {
    left: calc(50vw - 150px);
  }
}
@media all and (min-width: 992px) {
  .act-sim-popup.show {
    left: calc(50vw - 350px);
  }
}

.act-popup-title {
  font-size: max(15px, min(calc((100vw - 991px) * 9999), 20px));
}
.act-popup-100 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}
.act-btn {
  font-size: 12px;
  color: white;
  width: 70px;
  text-align: center;
  padding: 3px 5px;
  border-radius: 3px;
  border: 1px solid lightgray;
  cursor: pointer;
}
.act-btn:active {
  transform: translateY(2px);
}
.act-btn.blue {
  background-color: #0090ed;
}
.act-btn.red {
  background-color: #ed4d2d;
}
