.ordered-list-container {
  background-color: white;
}
.ordered-list-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f7f7;
}

.ordered-detail {
  display: none;
}

.ordered-detail.show {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  background-color: white;
  width: max(300px, min(calc((100vw - 991px) * 9999), 975px));
  max-height: 70vh;
  padding: max(10px, min(calc((100vw - 991px) * 9999), 20px));
  border-radius: 5px;
  box-shadow: 5px 5px 5px lightgray, 5px -5px 5px lightgray, -5px -5px 5px lightgray,
    -5px 5px 5px lightgray;
  overflow-y: scroll;
  z-index: 100;
  top: 10vh;
}
@media all and (max-width: 991px) {
  .ordered-detail.show {
    left: calc(50vw - 150px);
  }
}
@media all and (min-width: 992px) {
  .ordered-detail.show {
    left: calc(50vw - 487px);
  }
}

/*title*/
.ordered-list-title {
  font-weight: 700;
  font-size: max(13px, min(calc((100vw - 991px) * 9999), 25px));
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding-top: max(10px, min(calc((100vw - 991px) * 9999), 20px));
  padding-bottom: 5px;
}

/*pc*/
.ordered-list-pc {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*item*/
.ordered-item-container {
  background-color: white;
  width: calc(100% - 20px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 8px 8px 8px 0px;
  font-size: max(12px, min(calc((100vw - 991px) * 9999), 20px));
}
.ordered-item-container.le {
  background-color: rgb(240, 238, 238);
}
.ordered-item-container:hover {
  background-color: lightgray !important;
}

/*input*/
.ordered-detail-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ordered-input-container {
  width: 100%;
}
/*add order input*/
.ordered-input {
  position: relative;
  margin-top: 15px;
  width: calc(100%);
  border: 2px solid lightgray;
  border-radius: 8px 8px 8px 0px;
}
.ordered-input > input {
  width: 100%;
  height: max(25px, min(calc((100vw - 991px) * 9999), 40px));
  padding-left: 10px;
  opacity: 0;
  border: none;
  border-radius: 8px 8px 8px 0px;
  font-size: max(12px, min(calc((100vw - 991px) * 9999), 18px));
}
.ordered-input > textarea {
  width: 100%;
  height: max(60px, min(calc((100vw - 991px) * 9999), 85px));
  padding-left: 10px;
  opacity: 0;
  border: none;
  border-radius: 8px 8px 8px 0px;
  font-size: max(12px, min(calc((100vw - 991px) * 9999), 18px));
  line-height: max(14px, min(calc((100vw - 991px) * 9999), 21px));
  resize: none;
}
@media all and (max-width: 991px) {
  .ordered-input {
    margin-top: 15px;
    border-radius: 6px 6px 6px 0px;
  }
  .ordered-input > input {
    border-radius: 6px 6px 6px 0px;
  }
}
.ordered-input.focus-input {
  border-color: #009250;
}
.ordered-input-title {
  position: absolute;
  top: 20%;
  left: 20px;
  background-color: #fbfbfb;
  translate: 1s;
  font-size: max(12px, min(calc((100vw - 991px) * 9999), 18px));
  font-weight: 500;
  color: lightgray;
  height: fit-content;
  line-height: max(12px, min(calc((100vw - 991px) * 9999), 18px));
}
@media all and (max-width: 991px) {
  .ordered-input-title {
    top: 5px;
  }
}
.ordered-input.focus-input > input {
  opacity: 1;
}
.ordered-input.validate-input > input {
  opacity: 1;
}
.ordered-input.validate-input > textarea {
  opacity: 1;
}
.ordered-input.validate-input .ordered-input-title {
  top: -12px !important;
  color: #42494d;
}
@media all and (max-width: 991px) {
  .ordered-input.validate-input .ordered-input-title {
    top: -9px !important;
    color: #42494d;
  }
}

.ordered-item-status {
  text-align: right;
}

/*simList*/
.sim-list {
  margin-top: 10px;
  width: 100%;
  margin-bottom: 10px;
}

/*delete btn*/
.ordered-btn {
  cursor: pointer;
  background-color: #ed4d2d;
  color: white;
  margin: 5px;
  font-weight: 500;
  padding: 2px 10px;
  border-radius: 5px;
  box-shadow: 5px 5px 5px lightgray;
  font-size: min(3.5vw, 15px);
}
.ordered-btn.blue {
  background-color: #0090ed;
}
.ordered-btn:hover {
  background-color: #f33e1a;
}
.ordered-btn:active {
  transform: translateY(2px);
}

.ordered-list-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 5px 10px;
}

/*paging*/
.ordered-list-paging {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: max(13px, min(calc((100vw - 991px) * 9999), 20px));
  width: 100%;
}

/*paging btn*/
.order-item-btn {
  font-size: max(13px, min(calc((100vw - 991px) * 9999), 20px));
  width: max(20px, min(calc((100vw - 991px) * 9999), 25px));
  height: max(15px, min(calc((100vw - 991px) * 9999), 20px));
  border-radius: 3px;
  background-color: rgb(228, 226, 226);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.order-item-btn:hover {
  background-color: lightgray;
}
.order-item-btn:active {
  color: red;
  background-color: lightgray;
}
.order-item-btn.minus {
  margin-right: 20px;
}
.order-item-btn.plus {
  margin-left: 10px;
  margin-right: 10px;
}

/*select page size*/
.select-page {
  height: max(20px, min(calc((100vw - 991px) * 9999), 25px));
  width: max(30px, min(calc((100vw - 991px) * 9999), 35px));
  border-radius: 5px;
  font-weight: 500;
  font-size: max(12px, min(calc((100vw - 991px) * 9999), 15px));
  margin-left: 20px;
}
.select-size {
  height: max(20px, min(calc((100vw - 991px) * 9999), 25px));
  width: max(40px, min(calc((100vw - 991px) * 9999), 60px));
  border-radius: 5px;
  font-weight: 500;
  font-size: max(12px, min(calc((100vw - 991px) * 9999), 15px));
}
