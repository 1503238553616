.order-detail-blank {
  padding: 5px;
}

/*popup*/
.ordered-popup-status {
  display: flex;
  flex-direction: row;
  font-size: min(3.5vw, 15px);
}
.ordered-btn-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.ordered-icon-btn {
  font-size: min(3.5vw, 15px) !important;
  width: min(25vw, 125px);
  padding: 5px 10px;
}

/*note*/
.note-sim-component {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: min(3.5vw, 16px) !important;
}
.note-sim-date {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.note-sim-line {
  display: flex;
  flex-direction: row;
  flex: 1;
  border-bottom: 1px solid black;
  height: 100%;
  transform: translateY(-50%);
  color: transparent;
}

.note-sim-item {
  width: 100%;
  height: 80px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  background-color: #f5f5f5;
  border-bottom: 1px dashed gray;
}
.note-item-avt {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: lightgray;
  overflow: hidden;
}
.note-item-avt img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.note-sim-detail {
  margin: 0px 5px;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.note-text-inp {
  width: 100%;
  height: 80px;
  resize: none;
}
.note-sim-btn {
  margin: 10px 0px 10px 0px;
  background-color: #0090ed;
  color: white;
  padding: 2px 15px;
  border-radius: 5px;
  border-color: lightgray;
  font-size: min(3.5vw, 16px) !important;
}

/*bill btn*/
.btn-bill {
  position: fixed;
  top: 10px !important;
  font-size: 25px;
  color: lightgray;
  cursor: pointer;
  z-index: 101;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.btn-bill.download {
  left: 50px;
}
.btn-bill.close {
  left: 15px;
  color: red;
}
.btn-bill:hover {
  color: #0090ed;
}
.btn-bill:active {
  color: #0090ed;
}

/*full size image*/
.bill-full-image-container {
  display: none;
}
.bill-full-image-container.full-size {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: rgb(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.bill-full-image-container > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/*bill image*/
.bill-thumb-image-container {
  width: calc(100% - 20px);
  margin: 10px 0px;
  cursor: pointer;
}
@media all and (max-width: 991px) {
  .bill-thumb-image-container {
    width: calc(100% - 10px);
  }
}
.bill-thumb-image-container > img {
  width: 100%;
}
