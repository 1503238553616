/*add order input*/
.add-order-input-wrapper {
  margin-top: 25px;
}
.add-order-input {
  position: relative;
  width: calc(100vw - 20px);
  max-width: 326px;
  border: 2px solid lightgray;
  border-radius: 8px 8px 8px 0px;
}
.add-order-input > input {
  width: 100%;
  height: max(35px, min(calc((100vw - 991px) * 9999), 40px));
  padding-left: 10px;
  opacity: 0;
  border: none;
  font-weight: 600;
  border-radius: 8px 8px 8px 0px;
}
@media all and (max-width: 991px) {
  .add-order-input {
    border-radius: 6px 6px 6px 0px;
  }
  .add-order-input-wrapper {
    margin-top: 15px;
  }
  .add-order-input > input {
    border-radius: 6px 6px 6px 0px;
  }
}
.add-order-input.focus-input {
  border-color: #009250;
}
.add-order-input-title {
  position: absolute;
  top: 20%;
  left: 20px;
  background-color: #fbfbfb;
  translate: 1s;
  font-size: 18px;
  font-weight: 500;
  color: lightgray;
  transition: 0.5s;
  padding: 0;
  line-height: 18px;
}
@media all and (max-width: 991px) {
  .add-order-input-title {
    top: 10%;
  }
}
.add-order-input.focus-input .add-order-input-title {
  top: -35%;
  color: #42494d;
}
.add-order-input.focus-input > input {
  opacity: 1;
}
.add-order-input.validate-input > input {
  opacity: 1;
}
.add-order-input.validate-input .add-order-input-title {
  top: -35%;
  color: #42494d;
}

/*add order button*/
.add-order-btn {
  cursor: pointer;
  background-color: #0090ed;
  color: white;
  font-weight: 600;
  font-size: max(12px, min(calc((100vw - 991px) * 9999), 15px));
  border-radius: 10px;
  box-shadow: 4px 4px 5px gray;
  width: 100px;
  max-width: 326px;
  height: max(30px, min(calc((100vw - 991px) * 9999), 40px));
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px 8px 8px 0px;
  position: relative;
  overflow: hidden;
  margin-top: 10px;
}
.add-order-btn::before {
  content: '';
  background-color: #0090ed;
  border-radius: 8px 8px 8px 0px;
  top: 0px;
  left: -100%;
  position: absolute;
  width: 100%;
  height: 100%;
  transition: 0.5s;
}
.add-order-btn:hover::before {
  top: 0px;
  left: 0;
}
.add-order-btn::after {
  content: 'GỬI ĐƠN';
  border-radius: 8px 8px 8px 0px;
  top: 0px;
  left: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
@media all and (max-width: 991px) {
  .add-order-btn.btn1 {
    display: none;
  }
}

/*form container*/
.add-order-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 15px;
}
@media all and (min-width: 992px) {
  .add-order-form {
    width: 400px;
  }
}
.add-order-title {
  font-weight: 600;
  font-size: max(15px, min(calc((100vw - 991px) * 9999), 20px));
}

/*container*/
.add-order-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #fbfbfb;
}

/*detail*/
.add-order-detail-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media all and (min-width: 992px) {
  .add-order-detail-container {
    width: 50vw;
    overflow: auto;
  }
}

/*btn container*/
.add-order-btn-container {
  width: 60%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
@media all and (max-width: 991px) {
  .add-order-btn-container {
    width: calc(100vw - 20px);
    max-width: 322px;
  }
}
/*option*/
.add-order-option {
  width: 50%;
  max-width: 433px;
  height: max(35px, min(calc((100vw - 991px) * 9999), 40px));
  font-size: 18px;
  font-weight: 500;
}
@media all and (max-width: 991px) {
  .add-order-option {
    width: calc(40vw - 10px);
    max-width: 230px;
    border-radius: 6px 6px 6px 0px;
  }
}

/*add btn*/
.add-order-btn-wrapper {
  height: max(35px, min(calc((100vw - 991px) * 9999), 40px));
  outline: none;
  border-radius: 5px;
  background-color: #0090ed;
  color: white;
  padding: 0px 10px;
  border: 1px solid lightgray;
}
.add-order-btn-wrapper:active {
  transform: translateY(2px);
}

/**********************/
/***     Popup      ***/
/**********************/
.add-order-poopup-container {
  display: none;
}
.add-order-poopup-container.show {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(128, 128, 128, 0.5);
  top: 0;
  z-index: 200;
  font-size: 18px;
}
.add-order-popup-wrapper {
  width: calc(100vw - 20px);
  max-width: 322px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  font-weight: 500;
  height: 120px;
  justify-content: space-between;
}
.add-order-popup-btn-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.add-order-popup-detail {
  text-align: center;
}
.add-order-popup-btn-container > div {
  padding: 0px 8px;
  font-weight: 500;
  color: white;
  border-radius: 3px;
  cursor: pointer;
}
.add-order-popup-btn-container > div:active {
  transform: translateY(3px);
}
.add-order-popup-btn-container > div:nth-child(1) {
  background-color: #0090ed;
}
.add-order-popup-btn-container > div:nth-child(2) {
  background-color: #ed4d2d;
}

/*quantity*/
.add-order-quantity-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: center;
}
.add-order-quantity {
  width: 40px;
  text-align: center;
  margin: 0px 5px;
}

/*btn quantity*/
.add-order-quantity-btn {
  margin: 0px 5px;
  font-size: 15px;
  cursor: pointer;
}
.add-order-quantity-btn.plus {
  color: #0090ed;
}
.add-order-quantity-btn.minus {
  color: #ed4d2d;
}
.add-order-quantity-btn:active {
  transform: translateY(1px);
}
