/*wrapper*/
.header-wrapper {
  position: absolute;
  left: 0px;
}
@media all and (max-width: 991px) {
  .header-wrapper {
    position: fixed;
    bottom: 0px !important;
  }
}

/*pc*/
.header-container.pc {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  width: 250px;
  height: 100vh;
  box-shadow: 5px 0px 5px lightgray;
  border-radius: 0px 5px 5px 0px;
}
@media all and (max-width: 991px) {
  .header-container.pc {
    display: none !important;
  }
}

.header-logo {
  height: 50px;
}
.header-list-nav.pc {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 50px;
  align-items: flex-end;
  width: 100%;
  padding: 15px;
}

/*header nav item*/
.header-list-nav.pc > a {
  width: 100%;
  text-decoration: none;
  margin: 2px 0px;
}
.header-nav-item.pc {
  font-size: 15px;
  font-weight: 600;
  color: gray;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 25px;
  background-color: lightgray;
  text-align: center;
  height: 40px;
  border-radius: 5px;
}
.header-nav-item.pc > i {
  margin-right: 10px;
}
.header-list-nav .focus .header-nav-item {
  color: white;
  background-color: #0090ed;
}
.header-nav-item.pc:hover {
  color: white;
  background-color: #0090ed !important;
}

/*mobile*/
.header-container.mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  background-color: white;
  box-shadow: 0px -3px 3px lightgray;
  overflow: auto;
  width: 100vw;
}
@media all and (min-width: 992px) {
  .header-container.mobile {
    display: none;
  }
}

.header-container.mobile > a {
  margin: 0px 2px;
  text-decoration: none;
}
.header-container.mobile .header-nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: gray;
  flex: 1;
  font-size: 12px;
  padding: 2px 5px;
  min-width: 65px;
}
.header-container.mobile .focus .header-nav-item {
  color: #0090ed;
}

/*header bar*/
.header-container.mobile.top {
  position: fixed;
  top: 0;
  box-shadow: 0px 3px 3px lightgray;
  height: 50px;
}
.header-container.mobile.top a {
  text-decoration: none;
}
.header-container.mobile.top .header-nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: gray;
  flex: 1;
  font-size: 20px;
  padding: 2px 5px;
  min-width: 40px;
}
.header-container.mobile.top .focus .header-nav-item {
  color: #0090ed;
}

.header-icon-right {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
}
.header-logo-left {
  height: 100%;
}
.header-logo-left img {
  height: 100%;
  object-fit: contain;
}

/*noti*/
.header-nav-item.header-bar {
  position: relative;
}
.header-bar-count {
  position: absolute;
  background-color: red;
  color: white;
  font-size: 10px;
  width: 15px;
  height: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50%;
  top: -2px;
  right: 8px;
}
