.account-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}
.account-detail-title {
  width: 100%;
  font-weight: 500;
  padding: 7px 5px;
  border-bottom: 1px solid lightgray;
  background-color: white;
}
.account-detail {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
  width: 100%;
}
.account-detail-component.btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0;
}
.account-detail-left {
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 5px;
  align-items: center;
}
@media all and (max-width: 991px) {
  .account-detail-right {
    width: 100%;
  }
  .account-detail-left {
    width: 100%;
    border-bottom: 1px solid lightgray;
  }
}
@media all and (min-width: 992px) {
  .account-detail-right {
    display: flex;
    flex: 1;
  }
  .account-detail-left {
    min-height: 95vh;
    border-right: 1px solid lightgray;
  }
}
.account-detail-right {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.account-btn {
  width: 100%;
  text-align: left;
  padding: 8px 8px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  font-size: 18px;
}
.account-btn:hover {
  background-color: lightgray;
}
