.debit-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/*debit*/
.debit-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}
.debit-date-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 10px;
  align-items: center;
  height: 100%;
  border-bottom: 2px solid lightgray;
  padding: 5px;
}
@media all and (min-width: 992px) {
  .debit-date-container {
    border-right: 2px solid lightgray;
    min-height: 90vh;
    width: 300px;
  }
}
.debit-titlle {
  width: 100%;
  font-weight: 500;
}
.debit-date {
  padding: 2px 5px;
  border: 1px solid gray;
  width: calc(100% - 30px);
  font-weight: 400;
}

.debit-input-container {
  width: 100%;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.debit-btn {
  background-color: #0090ed;
  color: white;
  padding: 2px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.debit-btn:hover {
  background-color: #ed4d2d;
}
.debit-btn:active {
  transform: translateY(2px);
}
/*end of debit*/

/*detail*/
.debit-detail {
  display: flex;
  width: 100%;
  flex-direction: column;
}
@media all and (min-width: 992px) {
  .debit-detail {
    display: flex;
    flex: 1;
  }
}

.debit-detail-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}
.debit-detail-component {
  display: flex;
  flex: 1;
  padding: 2px 5px;
  font-weight: 400;
  min-width: 300px;
}
/*end of detail*/

/*table*/
.account-debit-table {
  border: 1px solid gray;
  margin: 2px;
  font-size: min(3vw, 12px);
}
@media all and (min-width: 992px) {
  .account-debit-table {
    font-size: 15px;
  }
}
.account-debit-table tr.le {
  background-color: gainsboro;
}
.account-debit-table td,
.account-debit-table tr,
.account-debit-table th {
  border: 1px solid gray;
  text-align: center;
}
.account-debit-table th {
  background-color: #0090ed;
  color: white;
  font-weight: 500;
}
/*end of table*/

/*copy*/
.debit-slide-from-date.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.debit-btn-show {
  background-color: #0090ed;
  color: white;
  cursor: pointer;
  padding: 3px 15px;
  border-radius: 5px;
  border: 1px solid lightgray;
}
.debit-btn-show:hover {
  background-color: #ed4d2d;
}
.debit-slide-from-date.btn {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.debit-radio-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  cursor: pointer;
}
.debit-radio-btn:hover {
  color: red;
}
.debit-slide-from-date {
  padding: 5px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  width: 100%;
}
.debit-select-component {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: center;
}

.add-order-input-wrapper.account-balance {
  margin: 5px;
}

@media all and (min-width: 991px) {
  .add-order-input-wrapper.account-balance {
    width: 100% !important;
  }
  .add-order-input-wrapper.account-balance .add-order-input {
    width: 100% !important;
  }
}
