.order-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  width: 60%;
  max-width: 650px;
  overflow: hidden;
}

/*image*/
.order-item-image {
  width: 150px;
  height: 100%;
  min-height: 50px;
  margin-right: 15px;
}
.order-item-image > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/*detail*/
.order-item-detail {
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;
  flex: 1;
  height: 100%;
}

/*title*/
.order-item-title {
  font-weight: 700 !important;
  font-size: 20px;
  margin-bottom: 0;
}

/*btn*/
.order-item-btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.order-item-btn {
  cursor: pointer;
}
.order-item-btn:hover {
  cursor: pointer;
  color: #ed4d2d;
}
.order-item-btn:active {
  cursor: pointer;
  color: #ed4d2d;
}
.order-item-btn.minus {
  margin-right: 5px !important;
}
.order-item-btn.plus {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

/*quantity*/
.order-item-quantity {
  font-size: max(10px, min(calc((100vw - 991px) * 9999), 20px));
  font-weight: 500;
  color: #ed4d2d;
  width: max(25px, min(calc((100vw - 991px) * 9999), 35px));
  height: max(20px, min(calc((100vw - 991px) * 9999), 25px));
  border: 1px solid #ed4d2d;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  text-align: center;
}

@media all and (max-width: 991px) {
  .order-item-container {
    min-width: 200px;
    margin-top: 5px;
    width: calc(100vw - 20px);
    max-width: 322px;
  }
  .order-item-image {
    width: 100px;
    height: 100%;
    margin-right: 8px;
  }
  .order-item-des {
    font-size: 4vw;
    margin: 0px;
  }
  .order-item-quantity {
    font-size: 15px !important;
    width: 25px;
    height: 20px;
  }
  .order-item-btn-container {
    font-size: 12px;
  }
  .order-item-title {
    font-weight: 700 !important;
    font-size: 15px;
  }
}

/*btn delete item*/
.btn-delete-order-item {
  cursor: pointer;
  color: gray;
}
.btn-delete-order-item:hover {
  color: red;
}
.btn-delete-order-item:active {
  color: red;
}

.order-item-index {
  font-size: min(4vw, 20px);
  margin-right: 5px;
}
