.sim-detail-item-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: max(11px, min(calc((100vw - 991px) * 9999), 18px));
  margin-top: 10px;
}
.sim-detail-item-image {
  width: max(80px, min(calc((100vw - 991px) * 9999), 150px));
}
.sim-detail-item-image > img {
  width: 100%;
  object-fit: contain;
}
.sim-detail-item-detail {
  margin-left: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}
.sim-detail-item-title {
  font-weight: 700;
}

.sim-detail-item-btn-container {
  display: flex;
  flex-direction: row;
}
