.popup-container {
  position: fixed;
  z-index: 250;
  width: fit-content;
  height: fit-content;
  border-radius: 5px;
  overflow: hidden;
  transition: 1s;
  box-shadow: 2px 2px 2px lightgray, -2px 2px 2px lightgray, 2px -2px 2px lightgray,
    -2px -2px 2px lightgray;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header-data-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: #348eed;
  color: white;
  font-weight: 500;
  z-index: 100;
  box-shadow: 0px 2px 2px lightgray;
}
.button-data-popup:active {
  transform: translateY(2px);
}

.btn-close-data-popup {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 25px;
  height: 25px;
  top: 5px;
  right: 5px;
}
.btn-close-data-popup:active {
  background-color: red;
}
.btn-close-data-popup > div {
  border-radius: 2px;
  position: absolute;
  background-color: #dc3545;
  width: 25px;
  height: 5px;
}
.btn-close-data-popup > div:nth-child(1) {
  top: 10px;
  transform: rotate(135deg);
}
.btn-close-data-popup > div:nth-child(2) {
  top: 10px;
  transform: rotate(-135deg);
}
