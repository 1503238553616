.base-container {
  background-color: #fbfbfb;
  min-height: 100vh;
  overflow: hidden;
}

/*main*/
.base-main {
  padding-top: 50px;
}
@media all and (max-width: 991px) {
  .base-main {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media all and (min-width: 992px) {
  .base-nav-container {
    position: fixed;
    top: 0px;
    left: -270px;
    transition: 0.5s;
  }
  .base-nav-container.showNav {
    left: 0px;
  }
}

/*head*/
.base-head {
  background-color: white !important;
  height: 50px !important;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2px 10px;
  box-shadow: 5px 5px 5px lightgray;
  position: fixed;
  width: 100vw;
  top: 0;
}
@media all and (max-width: 991px) {
  .base-head {
    justify-content: center;
  }
}
.base-head > div:nth-child(1) {
  font-weight: 700;
}
.base-head > div:nth-child(2) {
  height: 50px;
}
.base-head img {
  height: 50px;
}

/*btn show nav*/
.btn-show-nav {
  position: fixed;
  bottom: 0px;
  cursor: pointer;
  z-index: inherit;
  font-size: 35px;
  color: #ed4d2d;
  left: 10px;
}
@media all and (max-width: 991px) {
  .btn-show-nav {
    display: none;
  }
}

.base-head-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.base-head-icon {
  font-size: 20;
  margin: 5px 10px;
  padding: 5px;
  background-color: lightgray;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/*noti*/
.base-head-icon.noti {
  position: relative;
}
.base-head-icon.noti > i {
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.noti-count {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 14px;
}
