/*container*/
.sim-price-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*table*/
.table-sim-price th {
  font-weight: 500;
  border: 1px solid gray;
  background-color: #0090ed;
  color: white;
}
.table-sim-price {
  border: 1px solid gray;
  width: calc(100% - 10px);
  max-width: 500px;
  margin: 10px;
  font-size: min(3vw, 12px);
}
@media all and (min-width: 992px) {
  .table-sim-price {
    font-size: 15px;
  }
}
.table-sim-price tr {
  border: 1px solid gray;
  text-align: center;
}
.table-sim-price td {
  border: 1px solid gray;
  text-align: center;
}

/*title*/
.sim-price-title {
  font-weight: 500;
  font-size: 18px;
}
